.headLogo{
    height: 90px;
    width: auto;
}
.navLinkItem{
    margin: 6px 6px;
   color:#fff;
    border-radius: 4px;
    transition: 0.3s ease-in-out;    
}

.navLinkItem:hover{    
    color: #fff;
    /* box-shadow: 0px 2px 2px #f26522 ; */
}

.blueBg{
    background: rgb(255,125,20);
    background: linear-gradient(36deg, rgba(255,125,20,1) 0%, rgba(249,67,39,1) 90%);
}

.titleSanstha{
    font-size: 16px;
    font-weight: bolder;
    color: #E63206;
}
.titleHeader{
    font-size: 32px;
    font-weight: bold;
    color: #131C84;
    font-family:"Libre Baskerville";
}

.linkIcon{
    color: #e9e9e9;   
}

@media screen and (width < 720px) {
    .headLogo{
        height: 70px;
        width: auto;
    }

    .titleHeader{
        font-size: 18px;        
        font-weight: bold;
        color: #5B2C6F;
    }
  }